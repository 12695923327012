/* In your main CSS file (e.g., App.css) */

#wave {
  position: relative;
  height: 70px;
  width: 600px;
  background: #e0efe3;
}
#wave:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 340px;
  height: 80px;
  background-color: white;
  right: -5px;
  top: 40px;
}
#wave:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 300px;
  height: 70px;
  background-color: #e0efe3;
  left: 0;
  top: 27px;
}
.cloud-border {
  position: relative;
  display: inline-block;
}

.cloud-border::before,
.cloud-border::after {
  content: '';
  position: absolute;
  background: white; /* This is the cloud color */
  border-radius: 50%;
  z-index: -1;
}

.cloud-border::before {
  width: 200%;
  height: 100%;
  top: -50%;
  left: -50%;
  transform: rotate(30deg);
}

.cloud-border::after {
  width: 200%;
  height: 100%;
  bottom: -50%;
  right: -50%;
  transform: rotate(-30deg);
}

.cloud-border img {
  display: block;
  border-radius: 10px; /* Adjust this for rounded corners */
  z-index: 1; /* Make sure the image is above the pseudo-elements */
}